<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.25em"
    height="1.25em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M3 6h18v2H3V6m0 5h18v2H3v-2m0 5h18v2H3v-2Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IconMenu",
};
</script>
